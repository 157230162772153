import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect } from "react";
import { useState, useRef } from "react";

import "react-circular-progressbar/dist/styles.css";
import { useLocation } from "react-router-dom";
import apiConfig from "../../apiConfig";
import { PushNotifications } from '@capacitor/push-notifications';

import { Network } from '@capacitor/network';

import "./Home.css";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import WatiChat from "../../components/WatiChat";

const Home: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [dashboard, setDashboard] = useState<any>();

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);


  const [networkStatus, setNetworkStatus] = useState<any>(true);


  const [errorMessage, setErrorMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState([]);
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentYear, setCurrentYear] = useState(Number);

  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const getBookingId = (e: any) => {
    console.log("e----->>", e);
    localStorage.setItem('bookingId', e);
  }

  useIonViewWillLeave(() => {
    setDashboard(null);
  })

  useEffect(() => {

    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
      // if (status.connected == true) {
      //   dashboardFunction();
      // }
    });
    // logCurrentNetworkStatus();
  }, []);


  useIonViewWillEnter(() => {

    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed

    setCurrentMonth(month);
    setCurrentYear(year);

    console.log(month, year);

    checkPushNotificationPermissions();

    const fetchData = async () => {
      setLoading(true);
      setBackdropVisible(true);

      try {
        const response = await axios.get(`${apiConfig.baseUrl}/dashboard?id=${localStorage.getItem('userId')}&y=${year}&m=${month}`, {
          headers: {
            'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        setLoading(false);
        setBackdropVisible(false);
        setDashboard(response.data.data);

        const { calender_upcoming_bookings, calender_completed_booking } = response.data.data;
        setUpcomingBookings(calender_upcoming_bookings);
        setCompletedBookings(calender_completed_booking);

      } catch (error: any) {
        if (error.response.status == 401) {
          setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
          setSupendedTitle("Inactive");
          setShowSuspendedModal(true);
        }
        setLoading(false);
        setBackdropVisible(false);

        if (error.code === "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
      }
    };

    fetchData();
  }, []);



  const onChangeMonth = (newValue: Date) => {
    const monthNumber = newValue.getMonth(); // 0 to 11
    const year = newValue.getFullYear(); // e.g., 2024

    setCurrentMonth(JSON.stringify(monthNumber + 1));
    setCurrentYear(year);

    dashboardFunction(JSON.stringify(monthNumber + 1), year)

  }

  const onChangeArrows = (arrows: any) => {
    const activeStartDate = arrows.activeStartDate;

    // Get the month and year from the activeStartDate
    const monthNumber = activeStartDate.getMonth(); // 0 to 11
    const year = activeStartDate.getFullYear(); // e.g., 2024

    setCurrentMonth(JSON.stringify(monthNumber + 1));
    setCurrentYear(year);

    // Presumably you want to fetch or update dashboard data when navigating calendar months
    dashboardFunction(JSON.stringify(monthNumber + 1), year);
  }

  const renderDayContent = ({ date, view }: { date: Date; view: string }, upcomingBookings: any[], completedBookings: any[]) => {
    if (view === 'month') {
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
      const classes = ['non-clickable']; // Add the non-clickable class by default

      const hasUpcomingBooking = upcomingBookings.some(booking => booking.book_date === formattedDate);
      const hasCompletedBooking = completedBookings.some(booking => booking.book_date === formattedDate);

      // If it has an upcoming booking, add a specific class
      if (hasUpcomingBooking) {
        classes.push('upcoming-booking');
      }

      // If it has a completed booking, add another specific class
      if (hasCompletedBooking) {
        classes.push('completed-booking');
      }

      // If today has an upcoming booking, add a specific class
      if (new Date().toDateString() === date.toDateString() && hasUpcomingBooking) {
        classes.push('react-calendar__tile--now-upcoming');
      }

      // Return null if there are no classes to add, or the class string
      return classes.length > 0 ? classes.join(' ') : null;
    }
  };


  const checkPushNotificationPermissions = async () => {
    try {
      const { receive } = await PushNotifications.requestPermissions();

      if (receive === 'granted') {
        console.log('Push notifications are allowed');
      } else {
        console.log('Push notifications are not allowed');
      }
    } catch (error) {
      console.error('Error checking push notification permissions:', error);
    }
  };

  const dashboardFunction = async (month: any, year: any) => {
    setLoading(true);
    setBackdropVisible(true);

    try {
      const response = await axios.get(`${apiConfig.baseUrl}/dashboard?id=${localStorage.getItem('userId')}&y=${year}&m=${month}`, {
        headers: {
          'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      setLoading(false);
      setBackdropVisible(false);
      setDashboard(response.data.data);
      const slots = response.data.data.calender_completed_booking.map((slot: any) => slot.slotdate);
      setSelectedDates(slots);
      setUpcomingBookings(response.data.data.calender_upcoming_bookings);
      setCompletedBookings(response.data.data.calender_completed_booking);

    } catch (error: any) {
      if (error.response.status == 401) {
        setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
        setSupendedTitle("Inactive");
        setShowSuspendedModal(true);
      }
      setLoading(false);
      setBackdropVisible(false);

      if (error.code === "ERR_NETWORK") {
        setNetworkStatus(false);
      } else {
        setNetworkStatus(true);
      }
    }
  }


  const convertDate = (date: string) => {
    const parts = date.split('-');
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };



  const handleDashboardRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      dashboardFunction(currentMonth, currentYear);
      event.detail.complete();
    }, 1000);
  };
  const viewAllBookings = () => {
    localStorage.setItem("viewallbookings", "viewall");
  }




  return (
    <IonPage>
      {darkModeValue == "lightMode" &&
        <>
          <IonPage className=" ion-page-visible">
            <IonHeader className="ion-no-border center-grid">
              <IonToolbar className="home-toolbar header-toolbar">
                <IonImg slot="start" className="header-logo" src="assets/imgs/images/glamdeva-logo.svg"></IonImg>
                <IonButtons slot="end">
                  <IonButton
                    className="end-btn icon-btn-bell"
                    routerLink="/notifications"
                    routerDirection="none"
                  >
                    <IonIcon
                      className="fav header-icons bell-icon-m"
                      src="assets/imgs/icons/bellWhite.svg"
                      slot="end" />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="home">
              <div className="center-grid">
                <IonRefresher slot="fixed" onIonRefresh={handleDashboardRefresh}>
                  <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {networkStatus == false &&
                  <div className="no-network-div">
                    <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                    <h6 className='fontName'>Check your internet connection and try again</h6>
                  </div>
                }
                {networkStatus == true && dashboard &&
                  <>
                    <div style={{ position: "relative" }} className="background-holder">
                      <IonGrid className="top-grid">
                        <IonRow>
                          <IonCol size="12">
                            <div className="unread-div">
                              <IonLabel className="dash-main-title">
                                Dashboard
                              </IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size-md="6" size="12">
                            <div className="top-card animate__animated animate__bounceIn ion-padding-horizontal">
                              <IonGrid>
                                <IonRow>
                                  <IonCol size="2" className="margin-auto">
                                    <IonImg src="assets/imgs/icons/pound.svg" className="all-img" alt="pound"></IonImg>
                                  </IonCol>
                                  <IonCol size="4" className="margin-auto">
                                    <div className="dash-card-div">
                                      <IonLabel className="small-title">
                                        Revenue
                                      </IonLabel>
                                      <IonLabel className="overall-pounds">
                                        £{parseFloat(dashboard.revenue_this_year).toFixed(2)}
                                      </IonLabel>
                                      <IonLabel className="overall-title">
                                        This year
                                      </IonLabel>
                                    </div>
                                  </IonCol>
                                  <IonCol size="2" className="margin-auto">
                                    <IonImg src="assets/imgs/icons/pound.svg" className="all-img" alt="pound"></IonImg>
                                  </IonCol>
                                  <IonCol size="4" className="margin-auto">
                                    <div className="dash-card-div">
                                      <IonLabel className="small-title">
                                        Revenue
                                      </IonLabel>
                                      <IonLabel className="overall-pounds">
                                        £{parseFloat(dashboard.total_revenue).toFixed(2)}
                                      </IonLabel>
                                      <IonLabel className="overall-title">
                                        To date
                                      </IonLabel>
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </div>
                          </IonCol>
                          <IonCol size-md="6" size="12">
                            <div className="second-top-card animate__animated animate__bounceIn ion-padding-horizontal">
                              <IonGrid>
                                <IonRow>
                                  <IonCol size="2" className="margin-auto">
                                    <IonImg src="assets/imgs/icons/allBookings.svg" className="all-img" alt="allBookings"></IonImg>
                                  </IonCol>
                                  <IonCol size="4" className="margin-auto">
                                    <div className="dash-card-div">
                                      <IonLabel className="small-title">
                                        Bookings
                                      </IonLabel>
                                      <IonLabel className="overall-pounds">
                                        {dashboard.booking_this_year}
                                      </IonLabel>
                                      <IonLabel className="overall-title" >
                                        This year
                                      </IonLabel>
                                    </div>
                                  </IonCol>
                                  <IonCol size="2" className="margin-auto">
                                    <IonImg src="assets/imgs/icons/todaysBookings.svg" className="all-img" alt="todaysBookings"></IonImg>
                                  </IonCol>
                                  <IonCol size="4" className="margin-auto">
                                    <div className="dash-card-div">
                                      <IonLabel className="small-title">
                                        Bookings
                                      </IonLabel>
                                      <IonLabel className="overall-pounds">
                                        {dashboard.total_bookings}
                                      </IonLabel>
                                      <IonLabel className="overall-title">
                                        To date
                                      </IonLabel>
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                    <div className="margin-top animate__animated animate__fadeInUp animate__slow">
                      <div className="dash-m-title">
                        <IonLabel className="main-title monthly-main-title">New Bookings</IonLabel>
                      </div>
                      {dashboard.unconfirmed_bookings.length == 0 &&
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <div className="fontName nocard">
                                <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                <h6
                                  style={{
                                    color: "#000000",
                                    textAlign: "center",
                                    marginTop: "11%",
                                  }}
                                  className="fontName nocardh6"
                                >
                                  Sorry, there are currently no new bookings for you
                                </h6>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      }
                      <IonGrid>
                        <IonRow>
                          {dashboard.unconfirmed_bookings.slice(0, 3).map((user: any, i: any) => (
                            <>
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.listing_style.image1 ? user.listing_style.image1 : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-flex d-first-div">
                                          <IonLabel className="first-title">{user.listing_style.title}</IonLabel>
                                          <br />
                                          <IonLabel className="cat-subcat-title">{user.listing_categories.title} &gt; {user.listing_subcategories.title}</IonLabel>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align" src="assets/imgs/icons/calendar.svg" />
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_date} <span style={{ color: '#12877733' }}>|</span></IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_time}</IonLabel>
                                          </div>
                                          <div className="div-align d-d-block">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/person.svg" />
                                            <IonLabel className="label-align label-ellipsis middle-label"> {user.quote.customername}</IonLabel>
                                          </div>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="10">
                                        <div className="div-align">
                                          <IonLabel className="booking-id-chip">{user.booking_id}</IonLabel>
                                        </div>
                                        <div className="div-align" style={{ marginLeft: '2%' }}>
                                          {user.status == "3" && (
                                            <IonLabel className="unconfirmed-chips">Unconfirmed</IonLabel>
                                          )}
                                          {user.status == "7" && (
                                            <IonLabel className="modified-chips">Modified</IonLabel>
                                          )}
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="2">
                                        <div className="div-align">
                                          <IonLabel className="label-align pound">£{(parseFloat(user.subtotal) + parseFloat(user.travelcost)).toFixed(2)}</IonLabel>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size="12">
                                        <IonButton onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" expand="block" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                      {/* <IonCol size="4">
                                        <IonButton expand="block" onClick={() => { openAcceptBooking(user); getBookingId(user.id); }} className="accept-btn-bkng">
                                          Accept
                                        </IonButton>
                                      </IonCol>
                                      <IonCol size="4">
                                        <IonButton expand="block" onClick={() => { openRejectBooking(user); getBookingId(user.id); }} className="reject-bkg-btn">
                                          Reject
                                        </IonButton> 
                                       </IonCol> */}
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            </>
                          ))}
                        </IonRow>
                      </IonGrid>
                      {dashboard.unconfirmed_bookings.length !== 0 &&
                        <IonGrid>
                          <IonRow>
                            <IonCol size="12">
                              <IonButton onClick={viewAllBookings} routerLink="/tabs/bookings" routerDirection="none" expand="block" className="dash-view-all">
                                View All Bookings
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      }
                    </div>
                    <hr />

                    <div className="animate__animated animate__fadeInUp animate__slow">
                      <div className="dash-m-title">
                        <IonLabel className="main-title monthly-main-title">Upcoming Bookings</IonLabel>
                      </div>
                      {dashboard.upcoming_bookings.length == 0 &&
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <div className="fontName nocard">
                                <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                <h6
                                  style={{
                                    color: "#000000",
                                    textAlign: "center",
                                    marginTop: "11%",
                                  }}
                                  className="fontName nocardh6"
                                >
                                  Sorry, there are no upcoming bookings for you
                                </h6>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      }

                      <IonGrid>
                        <IonRow>
                          {dashboard && dashboard.upcoming_bookings.slice(0, 3).map((user: any, i: any) => (
                            <>
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="overall-list">
                                  <IonGrid >
                                    <IonRow>
                                      <IonCol size-md='11' size="11" className="second-col">
                                        <div className="d-flex first-div">
                                          <IonLabel className="first-title">{user.listing_style.title}</IonLabel>
                                          <br />
                                          <IonLabel className="cat-subcat-title">{user.listing_categories.title} &gt; {user.listing_subcategories.title}</IonLabel>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align" src="assets/imgs/icons/calendar.svg" />
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_date} <span style={{ color: '#12877733' }}>|</span></IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <IonLabel className="label-align date-time-color label-font-size"> {user.book_time}</IonLabel>
                                          </div>
                                          <br />
                                          <div className="div-align d-d-block">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/person.svg" />
                                            <IonLabel className="label-align label-ellipsis middle-label"> {user.quote.customername}</IonLabel>
                                          </div>
                                        </div>
                                      </IonCol>
                                      <IonCol size="1">
                                        <IonButtons className="upcoming-arrow-btn">
                                          <IonButton expand="block"
                                            onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none"
                                          >
                                            <IonIcon
                                              className="upcoming-arrow-icon"
                                              src="assets/imgs/icons/dash-right-arrow.svg"
                                            />
                                          </IonButton>
                                        </IonButtons>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <IonGrid>
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="10">
                                        <div className="div-align">
                                          <IonLabel className="booking-id-chip">{user.booking_id}</IonLabel>
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="2">
                                        <div className="div-align">
                                          <IonLabel className="label-align pound">£{(parseFloat(user.subtotal) + parseFloat(user.travelcost)).toFixed(2)}</IonLabel>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                  <hr />
                                </IonCard>
                              </IonCol>
                            </>
                          ))}
                        </IonRow>
                      </IonGrid>
                    </div>
                    <hr />
                    <IonGrid>
                      <IonRow>
                        <IonCol size-md="6" size="12">
                          <div className="animate__animated animate__fadeInUp animate__slow">
                            <div className="dash-m-title">
                              <IonLabel className="main-title monthly-main-title">Events:</IonLabel>
                            </div>
                            <div className="total-div">
                              <div className="green-square"></div>
                              <IonLabel className="total-label">Completed</IonLabel>
                              <div className="yellow-square"></div>
                              <IonLabel className="total-label">Upcoming</IonLabel>
                            </div>
                            <Calendar
                              onActiveStartDateChange={onChangeArrows}
                              onClickMonth={onChangeMonth}
                              tileClassName={({ date, view }) => renderDayContent({ date, view }, upcomingBookings, completedBookings)}
                            />
                          </div>
                        </IonCol>
                        <hr className="d-d-none" />
                        <IonCol size-md="6" size="12">
                          <div className="animate__animated animate__fadeInUp animate__slow">
                            <div className="dash-m-title">
                              <IonLabel className="main-title monthly-main-title">Account Health Status:</IonLabel>
                            </div>
                            <img className="dash-ahs-img" src="assets/imgs/images/ahsCard.png"></img>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                }

              </div>
              <IonLoading
                isOpen={loading}
                spinner="circular"
                translucent={true} />
              <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={errorMessage}
                duration={3000}
                color="danger"
              />
              <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
                <IonContent className="model-bg">
                  <IonGrid className='cnfmModelGrid'>
                    <IonRow>
                      <IonCol size="12" col-12>
                        <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                      </IonCol>
                      <IonCol size="12" col-12>
                        <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                      </IonCol>
                      <IonCol size="12" col-12>
                        <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                      </IonCol>
                      <IonCol size='12' col-12>
                        <IonButton
                          routerLink="/login"
                          routerDirection="none"
                          style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                          Back to Login
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonContent>
              </IonModal>
            </IonContent>
            <WatiChat />

          </IonPage>
        </>
      }
    </IonPage >
  );
};

export default Home;


